/* https://dashboardpack.com/live-demo-preview/?livedemo=2386&v=c562607189d7 */
html {
  min-height: 100% !important;
  /* background: #8e9eab;
  background: -webkit-linear-gradient(
    to top,
    #eef2f3,
    #8e9eab
  ); 
  background: linear-gradient(
    to top,
    #eef2f3,
    #8e9eab
  ); */
  background: #8e9eab; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #eef2f3,
    #8e9eab
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #eef2f3,
    #8e9eab
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
body {
  font-family: "Roboto", sans-serif;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
}

.main-window {
  min-height: 100% !important;
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-nospace {
  padding: 0px;
  margin: 0px;
}
.main-win {
  background-color: #9f9c99;
  height: 100%;
  width: 100%;
  background: #d4e7db;
  background: -webkit-linear-gradient(to top, #536976, #bbd2c5);
  background: linear-gradient(to top, #536976, #bbd2c5);
  position: fixed;
  scroll-behavior: auto;
  overflow-y: scroll;
}
.ap-info-val {
  font-size: 2rem;
  color: #64adaf;
  margin: 0;
  padding: 0;
}
.app-info-cont {
  width: 40%;
  text-align: center;
  margin-right: 20px;
}
.stats-cont {
  display: flex;
  width: 100%;
  /* border-bottom: 1px solid #f2f2f2; */
}
.unifi-info-select {
  display: flex;
  float: right;
}
.site-name {
  text-align: center;
  margin: 5%;
  color: #64adaf;
}
.unifi-tab-close {
  position: absolute;
  margin: 15px;
  cursor: pointer;
  top: 0px;
  left: 10px;
}
.eth-svg {
  width: 70px;
  height: auto;
  cursor: pointer;
}
.eth-svg g {
  fill: rgb(204, 77, 77);
}
.anim-slide-left {
  animation: 0.2s ease 0s 1 slideLeftAnimation;
}

.anim-fade-in {
  animation: 0.2s ease 0s 1 fadeInAnimation;
}
.anim-slide-up {
  animation: 1s ease 0s 1 slideUpAnimation;
}
.anim-slide-down {
  animation: 0.3s ease 0s 1 slideDownAnimation;
  transition: min-height 300ms;
}
.eth-icon-cont {
  width: 60px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  transition: 0.5s;
  cursor: pointer;
}
.eth-icon-cont:hover {
  box-shadow: 0px 0px 15px 1px rgba(90, 90, 90, 0.75);
}
.box-frame {
  margin: 0.5%;
  background-color: white;
  border: 1px solid rgb(240, 240, 240);
  box-shadow: inset 0px 0px 25px -16px rgba(43, 48, 46, 0.15);
  /* box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.75); */
}
.wrapper-main {
  margin: 0.5%;
  border: 1px solid#dcdcdc;
  border-radius: 8px;
  /* -webkit-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.3); */
}
.modal-close-btn {
  position: absolute;
  right: 0;
}
.rocket-spinner {
  position: relative;
  margin: auto;
  box-sizing: border-box;
  background-clip: padding-box;
  width: 80px;
  height: 80px;
  border-radius: 100px;
  border: 4px solid rgba(255, 255, 255, 0.1);
  -webkit-mask: linear-gradient(rgba(0, 0, 0, 0.1), #000000 90%);
  transform-origin: 150% 60%;
  transform: perspective(200px) rotateX(66deg);
  animation: rocket-spinner-wiggle 2.2s infinite;
}
.rocket-spinner:before,
.rocket-spinner:after {
  content: "";
  position: absolute;
  margin: -4px;
  box-sizing: inherit;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  opacity: 0.05;
  border: inherit;
  border-color: transparent;
  animation: rocket-spinner-spin 2.2s cubic-bezier(0.6, 0.2, 0, 0.8) infinite,
    rocket-spinner-fade 20.2s linear infinite;
}

.rocket-spinner:before {
  border-top-color: #62e969;
}

.rocket-spinner:after {
  border-bottom-color: #fab19a;
  border-top-color: #f8ec80;
  animation-delay: 0.3s;
}
.ap-drawer {
  min-width: 300px;
}
.spin-anim {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
.item-hover {
  background-color: white;
  transition: all 0.5s;
}
.item-hover:hover {
  background-color: #f8f8ff;
}
.dash-icon-container {
}
.devices-main {
  width: 100%;
  margin-top: 65px;
  display: flex;
  min-height: 92.4vh;
  flex-wrap: nowrap;
  /* border: 2px solid red; */
}
.devices-1 {
  /* box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.75); */
  /* border: 2px solid #a9a9a9; */

  /* flex: 0 0 220px; */
  flex-direction: column;
  width: 260px;
  overflow-x: hidden;
  /* padding: 15px; */
  margin: 25px;
  margin-left: 0;
  margin-top: 20px;
  /* position: fixed; */
  height: 81.5vh;
  /* overflow-y: hidden; */
  /* box-shadow: 0 0.46875rem 2.1875rem rgba(31, 10, 6, 0.03),
    0 0.9375rem 1.40625rem rgba(31, 10, 6, 0.03),
    0 0.25rem 0.53125rem rgba(31, 10, 6, 0.05),
    0 0.125rem 0.1875rem rgba(31, 10, 6, 0.03); */
}
.devices-2 {
  margin: 3px;
  margin-left: 0;
  padding-right: 50px;
  /* flex: 0 0 85%; */
  width: 100%;
  min-height: 89vh;
}
.meniu-main {
  margin-top: 10px;
  width: 265px;
  position: fixed;
  height: 90%;
  margin-left: 10px;
  z-index: 99;
}
.mt-zabbix-ch {
  display: flex;
}
.justif-center {
  justify-content: flex-end;
}
.flex-wrap-break {
  flex-wrap: nowrap;
}
.sidebar-main {
  border: 1px solid #e9e9e9;
  position: fixed;
  z-index: 999;
  background-color: white;
  right: 0;
  top: 0;
  height: 99.8vh;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

@media (max-width: 1100px) {
  .flex-wrap-break {
    flex-wrap: wrap;
  }
}
@media (max-width: 600px) {
  .mt-zabbix-ch {
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .justif-center {
    justify-content: center;
  }
  .flex-wrap-break {
    flex-wrap: wrap;
  }
}
@media (max-width: 1000px) {
  .flex-wrap-break {
    flex-wrap: wrap;
  }
  .margin-rp {
    margin: 20px;
  }
  .mt-zabbix-ch {
    justify-content: center;
    align-items: center;
  }
  .devices-2 {
    margin: 0px;
    margin-left: 0;
    padding-right: 0px;
    /* padding-left: 10px; */
  }
  .devices-1 {
    height: 200px;
    width: 94vw;
  }
  .meniu-main {
    position: static;

    width: 95.5vw;
    height: 300px;
  }
  .devices-main {
    width: 97vw;
    flex-wrap: wrap;
    /* border: 2px solid red; */
    justify-content: center;
  }
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px rgb(196, 196, 196); */
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 10px;
  box-shadow: inset 0 0 2px rgb(46, 46, 46);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #949494;
}
.dash-status-cont {
  width: 30%;
}
.dash-status-item {
  min-height: 90px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  display: flex;
  background-color: #f5f5f5;
  transition: all 0.5s;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 4px;
  letter-spacing: 0.01071em;
}
.back-red {
  color: rgb(255, 255, 255);
  background-color: rgb(252, 93, 76);
}
.back-green {
  background-color: #4caf50;
  color: white;
}
.back-yellow {
  background-color: #ff1100;
  color: white;
}
.p-nospace {
  margin: 0;
  padding: 0;
}
.vis-network:focus {
  outline: none;
}
.menu-item {
  margin: 0px;
  padding-left: 15px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.menu-selected {
  background-color: #eeeeee;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
}
.menu-selected::before {
  content: "»";
  margin-right: 5px;
  color: red;
  font-weight: bold;
}
.login-bck {
  background: #8e9eab;
  background: -webkit-linear-gradient(to top, #eef2f3, #8e9eab);
  background: linear-gradient(to top, #eef2f3, #8e9eab);
}
input[type="date"]::-webkit-datetime-edit,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  color: #fff;
  position: relative;
}

input[type="date"]::-webkit-datetime-edit-year-field {
  position: absolute !important;
  /* border-left: 1px solid #8c8c8c; */
  padding: 0px;
  color: black;
  left: 0px;
}

input[type="date"]::-webkit-datetime-edit-month-field {
  position: absolute !important;
  /* border-left: 1px solid #8c8c8c; */
  padding: 0px;
  color: #000;
  left: 46px;
}

input[type="date"]::-webkit-datetime-edit-day-field {
  position: absolute !important;
  color: #000;
  padding: 0px;
  left: 72px;
}
.time-input {
  width: 25px;
  transition: all 0.3s;
}
.time-input:hover {
  width: 37px;
}
.time-input input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0px;
}
.time-input:hover input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: button;
}
.time-input input[type="number"] {
  -moz-appearance: textfield;
}
.time-input:hover input[type="number"] {
  -moz-appearance: number;
}

@keyframes rocket-spinner-spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rocket-spinner-fade {
  5% {
    opacity: 0.1;
  }
  7% {
    opacity: 0.5;
  }
  10% {
    opacity: 1;
  }
  18% {
    opacity: 0.1;
  }

  20% {
    opacity: 0;
  }
}

@keyframes slideLeftAnimation {
  0% {
    opacity: 0;
    transform: translateX(20%);
  }
  100% {
    opacity: 1;

    transform: translateX(0);
  }
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    /* transform: translateY(3%); */
    transform: scale(0.98, 0.98);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
    /* transform: translateY(0); */
  }
  /* 0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-100px);
  }
  50% {
    transform: scale(1, 1) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  } */
}

@keyframes slideUpAnimation {
  0% {
    opacity: 0;
    /* transform: translateY(3%); */
    transform: scale(0.98, 0.98);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
    /* transform: translateY(0); */
  }
}

@keyframes slideDownAnimation {
  0% {
    opacity: 0;
    transform: translateY(-1%);
  }
  100% {
    opacity: 1;

    transform: translateY(0);
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .ap-drawer {
    min-width: 50vw;
  }
  .dash-icon-container {
    width: 100%;
  }
  .dash-status-cont {
    width: 80%;
  }
  .margin-resp-r {
    margin-right: 25px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .ap-drawer {
    min-width: 50vw;
  }
  .dash-icon-container {
    display: inline-block;
  }
  .dash-status-cont {
    width: 80%;
  }
  .margin-resp {
    margin-left: 25px;
  }
  .margin-resp-r {
    margin-right: 25px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .ap-drawer {
    min-width: 100vw;
  }
  .dash-icon-container {
    display: inline-block;
  }
  .dash-status-cont {
    width: 80%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .ap-drawer {
    min-width: 300px;
  }
  .dash-status-cont {
    width: 80%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .ap-drawer {
    min-width: 300px;
  }
  .dash-status-cont {
    width: 700px;
  }
}
